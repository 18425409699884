<template>
  <div>
    <v-card-text>
      <v-container class="row1">
        <v-row>
          <v-col cols="14" sm="8" md="8">
            <v-row dense class="mb-5">
              <router-link
                v-bind:to="{
                  path: '/stock-invoice/' + productInfo.stockInvoiceId
                }"
                style="text-decoration: none;"
              >
                <v-btn rounded color="#05a2fa">
                  <v-icon color="white">
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </router-link>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="4" md="8" class="mx-auto">
            <v-card>
              <div style="float:right; margin-top:10px; margin-right:10px;">
                <v-icon
                  color="#05a2fa"
                  @click="editItem()"
                  style="margin-right:5px"
                >
                  mdi-pencil-outline
                </v-icon>
                <v-icon color="#FF0000" @click="deleteItem()">
                  mdi-trash-can-outline
                </v-icon>
              </div>
              <!-- <v-card-title>
                stockInvoiceProductId : {{ productInfo.stockInvoiceProductId }}
                <br />
                stockInvoiceId :
                {{ productInfo.stockInvoiceId }}<br />
                locationId: {{ productInfo.locationId }} <br />
                productId :
                {{ productInfo.productId }}
              </v-card-title> -->
              <v-card-title style="font-size:20px">
                Product Name : <br />{{ productInfo.product.productName }}
              </v-card-title>
              <v-card-text> Quantity: {{ productInfo.quantity }} </v-card-text>
              <v-divider class="mx-4"></v-divider>

              <v-card-text>
                Description : {{ productInfo.product.description }} <br />
                EAN : {{ productInfo.product.ean }} <br />
                SKU : {{ productInfo.product.sku }} <br />
                Stock Available :

                <span
                  v-if="productInfo.product.isOutOfStock === true"
                  class="red--text"
                >
                  No
                </span>
                <span
                  v-if="productInfo.product.isOutOfStock === false"
                  class="green--text"
                >
                  Yes
                </span>
                <br />

                Product Available :
                <span
                  v-if="productInfo.product.available === true"
                  class="green--text"
                >
                  Yes
                </span>
                <span
                  v-if="productInfo.product.available === false"
                  class="red--text"
                >
                  No
                </span>

                <br />

                Price : {{ productInfo.product.price }} <br />
                Sale :
                <span
                  v-if="productInfo.product.sale === true"
                  class="green--text"
                >
                  Yes
                </span>
                <span
                  v-if="productInfo.product.sale === false"
                  class="red--text"
                >
                  No
                </span>
                <br />
                Sale Price : {{ productInfo.product.salePrice }} <br />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-dialog v-model="dialogEdit" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Edit Product</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="ProductForm">
                <v-row>
                  <!-- <v-col cols="12">
                    <v-select
                      v-model="editedProductInfo.stockInvoiceId"
                      :items="editedProductInfo"
                      item-text="stockInvoiceId"
                      item-value="stockInvoiceId"
                      label="Stock Invoice Id"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="editedProductInfo.productId"
                      :items="editedProductInfo"
                      item-text="productId"
                      item-value="productId"
                      label="Product Id"
                      outlined
                      clearable
                    />
                  </v-col> -->
                  <!-- <v-col cols="12">
                    <v-text-field
                      type="number"
                      class="inputNumber"
                      v-model="editedProductInfo.ean"
                      label="EAN"
                    />
                  </v-col> -->

                  <v-col cols="12">
                    <v-text-field
                      type="number"
                      class="inputNumber"
                      v-model="editedProductInfo.quantity"
                      label="Quantity"
                      outlined
                      clearable
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-select
                      v-model="editedProductInfo.locationId"
                      :items="locations"
                      item-text="locationName"
                      item-value="locationId"
                      label="Location Id"
                      outlined
                       
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="dialogEdit = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="saveEdit"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline">
            Are you sure you want to delete this item?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="trashItem">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </div>
</template>

<style>
.row1 {
  margin-top: -25px;
}
.inputNumber input[type="number"] {
  -moz-appearance: textfield;
}
.inputNumber input::-webkit-outer-spin-button,
.inputNumber input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>

<script>
export default {
  data() {
    return {
      dialogEdit: false,
      dialogDelete: false,
      dialogRestore: false,
      dialogTrash: false,
      search: "",

      customerAccounts: [],
      guests: [],

      status: [
        { text: "Active", value: true },
        { text: "InActive", value: false }
      ],

      editedIndex: -1,
      editedProductInfo: {
        productName: "",
        description: "",
        ean: "",
        sku: "",
        isOutOfStock: true,
        available: true,
        price: "",
        sale: true,
        salePrice: "",
        categoryId: "",
        locationId: 1
      },

      productInfo: {},
      locations: {}
    };
  },

  watch: {
    dialogEdit(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  created() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      var currentUrl = window.location.pathname;
      let vars = currentUrl.split("/");
      vars.reverse();
      let url = vars[0];
      url = parseInt(url);

      this.$http.get("/WristBand").then(response => {
        this.wristbands = response.data.data;
      });
      this.$http.get("/CustomerAccount").then(response => {
        this.customerAccounts = response.data.data;
      });
      this.$http.get("/Guest").then(response => {
        this.guests = response.data.data;
      });
      this.$http.get(`/ProductStock/${url}`).then(response => {
        this.productInfo = response.data.data;
      });
      this.$http.get("/Location").then(response => {
        this.locations = response.data.data;
      });
    },

    editItem() {
      this.editedProductInfo = Object.assign({}, this.productInfo);
      this.dialogEdit = true;
    },

    deleteItem() {
      this.dialogDelete = true;
    },

    trashItem() {
      var id = this.productInfo.stockInvoiceId;
      this.$http
        .post("/ProductStock/Trash", this.productInfo)
        .then(response => {
          if (response.data.success === true) {
            this.$toast.success("Deleted successfully.", "Success", {
              position: "topRight"
            });
            this.$router.push(`/stock-invoice/${id}`);
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight"
            });
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.$toast.error(" Deleting Process Failed", "Error", {
              position: "topRight"
            });
          }
        });
    },

    deleteItemConfirm() {
      var Id = this.editedProductInfo.wristbandId;
      this.$http
        .delete("/Wristband/" + Id, null, {
          params: { Id: this.editedProductInfo.id }
        })
        .then(response => {
          if (response.status === 200) {
            this.$toast.success("Wristband deleted successfully.", "Success", {
              position: "topRight"
            });
          }
          this.wristbands.shift(response.data.data);
        })

        .catch(error => {
          if (error.response.status === 404) {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight"
            });
          }
        });
      //this.users.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedProductInfo = Object.assign({}, this.defaultProduct);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedProductInfo = Object.assign({}, this.defaultProduct);
        this.editedIndex = -1;
      });
    },

    saveEdit() {
      if (this.$refs.ProductForm.validate()) {
        var Id = this.productInfo.productId;
        this.$http
          .put("/Product/" + Id, this.editedProductInfo)
          .then(response => {
            if (response.data.success === true) {
              this.productInfo = response.data.data;
              this.$toast.success("Product updated successfully.", "Success", {
                position: "topRight"
              });
            } else {
              this.$toast.error(response.data.message, "Error", {
                position: "topRight"
              });
            }
          })
          .catch(error => {
            if (error.response.status) {
              this.$toast.error(" Updating Process Failed", "Error", {
                position: "topRight"
              });
            }
          });
        this.dialogEdit = false;
      }
    },

    save() {
      if (this.$refs.WristbandsForm.validate()) {
        if (this.editedIndex > -1) {
          const data = this.editedProductInfo;
          const index = this.editedIndex;
          var Id = this.editedProductInfo.wristbandId;
          this.$http.put("/WristBand/" + Id, data).then(response => {
            if (response.data.success === true) {
              this.wristbands.shift(response.data.data);
              //  this.$set(this.users, index, data);
              this.$toast.success("User updated successfully.", "Success", {
                position: "topRight"
              });
              this.wristbands.unshift(response.data.data);
            } else {
              this.$toast.error(response.data.message, "Error", {
                position: "topRight"
              });
            }
          });
        } else {
          this.$http
            .post("/WristBand", this.editedProductInfo)
            .then(response => {
              if (response.data.success === true) {
                this.$toast.success(
                  "Wristband added successfully.",
                  "Success",
                  {
                    position: "topRight"
                  }
                );
                this.wristbands.unshift(response.data.data);
              } else {
                this.$toast.error(response.data.message, "Error", {
                  position: "topRight"
                });
              }
            })
            .catch(error => {
              if (error.response.status) {
                this.$toast.error("Adding Process Failed", "Error", {
                  position: "topRight"
                });
              }
            });
        }
        this.close();
      }
    },

    getStatusColor(data) {
      switch (data) {
        case "true":
          return "success";
        case "false":
          return "error";
        default:
          return "";
      }
    }
  }
};
</script>
